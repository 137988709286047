import React from "react"
import PropTypes from "prop-types"

import useClickOutside from "../../hooks/useClickOutside"

import ProductCard from "./ProductCard"

import "./ProductList.scss"

const ProductListInfo = ({ title, products, search }) => {
  const clickRef = React.useRef()

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  const [isSorted, setIsSorted] = React.useState(false)
  const [sortedProducts, setSortedProducts] = React.useState(products)

  const toggleIsDropdownOpen = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  useClickOutside(clickRef, () => {
    setIsDropdownOpen(false)
  })

  const sortByNew = () => {
    setIsSorted(true)
    const updatedProducts = sortedProducts.sort((product1, product2) => {
      if (product1.publishedAt < product2.publishedAt) {
        return 1
      } else if (product1.publishedAt > product2.publishedAt) {
        return -1
      }
      return 0
    })
    setSortedProducts([...updatedProducts])
  }
  const sortByPopular = () => {
    setIsSorted(false)
  }
  const sortByAsc = () => {
    setIsSorted(true)
    const updatedProducts = sortedProducts.sort((product1, product2) => {
      if (product1.title > product2.title) {
        return 1
      } else if (product1.title < product2.title) {
        return -1
      }
      return 0
    })
    setSortedProducts([...updatedProducts])
  }
  const sortByDesc = () => {
    setIsSorted(true)
    const updatedProducts = sortedProducts.sort((product1, product2) => {
      if (product1.title > product2.title) {
        return -1
      } else if (product1.title < product2.title) {
        return 1
      }
      return 0
    })
    setSortedProducts([...updatedProducts])
  }

  return (
    <section className="product-list">
      <div className="product-list__container">
        <div className="product-list__container__heading-section">
          <div className="product-list__container__heading-section__title">
            {title}
          </div>
          <div
            className="product-list__container__heading-section__dropdown"
            ref={clickRef}
          >
            <button
              className="product-list__container__heading-section__dropdown__button"
              onClick={toggleIsDropdownOpen}
            >
              Sort by
            </button>
            <div
              className={`product-list__container__heading-section__dropdown__content ${
                isDropdownOpen ? "is-open" : ""
              }`}
            >
              <div onClick={sortByNew}>NEWEST</div>
              <div onClick={sortByPopular}>POPULAR</div>
              <div onClick={sortByAsc}>ASCENDING (A - Z)</div>
              <div onClick={sortByDesc}>DESCENDING (Z - A)</div>
            </div>
          </div>
        </div>
        <div className="product-list__container__divider" />
        <div className="product-list__container__product-cards">
          <div className="product-list-card">
            {isSorted
              ? sortedProducts.map((productNode) => {
                  if (search)
                    productNode.images[0].originalSrc =
                      productNode.images[0].src
                  return (
                    <ProductCard key={productNode.id} product={productNode} />
                  )
                })
              : products.map((productNode) => {
                  if (search)
                    productNode.images[0].originalSrc =
                      productNode.images[0].src
                  return (
                    <ProductCard key={productNode.id} product={productNode} />
                  )
                })}
          </div>
        </div>
      </div>
    </section>
  )
}

ProductListInfo.propTypes = {
  title: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  search: PropTypes.bool,
}

export default ProductListInfo
