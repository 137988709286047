import React from "react"
import PropTypes from "prop-types"

import Link from "../../utils/link"

const ProductCard = ({ product }) => {
  return (
    <Link
      to={`/shop/${product.handle}/`}
      className="product-list-card__section"
    >
      <div className="product-list-card__section__border">
        <div className="product-list-card__section__border__outer">
          <div className="product-list-card__section__border__inner">
            <div className="product-list-card__section__border__image">
              <div
                style={{
                  backgroundImage: `url(${product.images[0].originalSrc})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="product-list-card__section__text">
        <div className="product-list-card__section__text__name">
          {product.title}
        </div>
        <div className="product-list-card__section__text__divider" />
        <div className="product-list-card__section__text__type">
          {product.productType}
        </div>
        {product.priceRange && (
          <div className="product-list-card__section__text__price">
            $ {Number(product.priceRange.minVariantPrice.amount).toFixed(2)} - ${" "}
            {Number(product.priceRange.maxVariantPrice.amount).toFixed(2)}
          </div>
        )}
      </div>
    </Link>
  )
}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductCard
